import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { firstValueFrom, Observable } from 'rxjs';
import { IContact } from 'src/app/models/interfaces/IContact';
import { IFaq } from 'src/app/models/interfaces/IFaq';
import { ITerm } from 'src/app/models/interfaces/ITerm';
import { BookingService } from 'src/app/shared/services/booking.service';
import { CacheService } from 'src/app/shared/services/cache.service';
import { LoginService } from 'src/app/shared/services/login.service';
import { SecurityService } from 'src/app/shared/services/security.service';

@Component({
  selector: 'app-main-template',
  templateUrl: './main-template.component.html',
  styleUrls: ['./main-template.component.scss'],
  animations: [
    trigger('collapse', [
      state('false', style({ height: AUTO_STYLE, display: AUTO_STYLE })),
      state('true', style({ height: '0', display: 'none' })),
      transition('false => true', animate(300 + 'ms ease-in')),
      transition('true => false', animate(300 + 'ms ease-out')),
    ]),
  ],
})
export class MainTemplateComponent {
  @Input() showFaq = false;
  @Input() title!: string;
  @Input() showFooterBread = true;
  @Input() breadcrumb!: string[];
  @Input() customHeader = false;
  @Input() showMenu = true;

  faq: IFaq[] = [];

  listTerms$: Observable<ITerm[]>;
  routeLogin: string;
  isLogged: boolean = null;
  contactData: IContact;
  loader = false;

  constructor(
    private route: ActivatedRoute,
    private bookingService: BookingService,
    private loginService: LoginService,
    private router: Router,
    private securityService: SecurityService,
    private cacheService: CacheService
  ) {}

  async ngOnInit() {
    this.bookingService.loader$.subscribe((data) => {
      this.loader = data;
    });

    this.loginService.loader$.subscribe((data) => {
      this.loader = data;
    });

    if (this.showFaq) {
      this.getFaq();
      this.contactData = await firstValueFrom(this.bookingService.getContact());
    }

    this.isLogged = this.cacheService.checkUserAuth();

    this.listTerms$ = this.bookingService.getListTerms();

    this.routeLogin = `/login?returnUrl=${location.pathname}`;

    this.securityService.isLoggedIn.subscribe((data) => {
      this.isLogged = data;
    });
  }

  navigateToLogin() {
    this.logEventAnalytics('AcessarConta-Header');

    const queryParams: Params = {
      returnUrl: location.pathname.replace(/\//, ''),
    };
    this.router.navigate(['/login'], { queryParams });
  }

  getFaq() {
    if (this.faq.length > 0) {
      return;
    }
    this.bookingService.getFaq().subscribe((data) => {
      this.faq = data.map((item) => {
        item.answer = item.answer.replaceAll('\r\n', '<br/>');
        return item;
      });
    });
  }

  collapse(faqItem: IFaq) {
    faqItem.expanded = !faqItem.expanded;
    this.faq
      .filter((item) => item.question !== faqItem.question)
      .forEach((item) => (item.expanded = false));
  }

  logout() {
    this.logEventAnalytics('Sair-Header');
    this.securityService.logout();
  }

  onClickButtonContact() {
    const pathName = location.pathname;

    switch (pathName) {
      case '/':
        this.logEventAnalytics('FaleConosco-Home');
        break;
      case '/minha-conta/minhas-reservas':
        this.logEventAnalytics('FaleConosco-MinhasReservas');
        break;
      case '/reserva/data':
        this.logEventAnalytics('FaleConosco-Society-Step2');
        break;
    }
  }

  logEventAnalytics(event: string) {
    logEvent(getAnalytics(), event);
  }
}
